import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { WindowService } from "src/app/services/window.service";
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { SignUpComponent } from "../sign-up/sign-up.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {
  loading: boolean = false;
  windowRef: any;
  public isOtpReceived: boolean = false;
  showAlert: boolean = false;
  otpLength: number = 0;
  alertMsg: string = "";
  alertType: string = "danger";
  otp: number = 0;
  countryCode : string;
  isError: boolean = false;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "40px",
    },
  };
  phoneNumberForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<SignUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,    
    private win: WindowService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
    this.windowRef.recaptchaVerifier.render();
    this.phoneNumberForm = this.fb.group({
      phoneNumber: ["", [Validators.required, Validators.pattern("[0-9]*")]],
    });
    this.onLoad();
  }
  onLoad() {
    this.countryCode = this.data.countryCode;
    this.phoneNumberForm.get("phoneNumber").setValue(this.data.phoneNumber);
  }
  sendMobileNumber() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = this.phoneNumberForm.get("phoneNumber").value;
    const dailCode = '+91';
    this.showAlert = false;
    firebase
      .auth()
      .signInWithPhoneNumber(dailCode + num, appVerifier)
      .then((result) => {
        this.windowRef.confirmationResult = result;
        this.isOtpReceived = true;
        this.showAlert = false;
      })
      .catch((error) => {
        console.log(error);
        this.isOtpReceived = false;
        this.alertType = "danger";
        this.alertMsg = "Incorrect Mobile Number?";
        this.showAlert = true;
      });
  }
  onOtpChange(event) {
    this.otp = event;
    this.otpLength = event.length;
    if (event.length == 6) {
      this.isError = false;
    }
  }
  verify() {
    if (this.otpLength != 6) {
      this.alertType = "danger";
      this.alertMsg = "Incorrect code entered..!";
      this.showAlert = true;
      return;
    }
    this.windowRef.confirmationResult
      .confirm(this.otp)
      .then((result) => {
        this.dialogRef.close(true);
      })
      .catch((error) => {
        this.alertType = "danger";
        this.alertMsg = "Incorrect code entered?";
        this.showAlert = true;
      });
  }
  closeTab() {
    this.dialogRef.close(false);
  }

}


