import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  public baseUrl: string = "https://india-live-api.talentasker.com";
  public userID: string = "";
  showLoader = new BehaviorSubject(false);
  public checkIsLoggedIn = new BehaviorSubject(false);
  public checkIsAdminLoggedIn = new BehaviorSubject(false);
  public checkIsAdminLogedout = new BehaviorSubject(false);
  public isLoggedIn = new BehaviorSubject(false);
  public callLogin = new BehaviorSubject(false);
  public setUserId = new BehaviorSubject(false);
  public callSignUp = new BehaviorSubject(false);
  public callEhire = new BehaviorSubject(false);
  public callSignOut = new BehaviorSubject(false);
  public callPostJob = new BehaviorSubject(false);
  public callForgotPass: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public gotOtp: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public checkIsImgUpdated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public IsAdminLogout = new BehaviorSubject(false);
  private Findurl: boolean = true;
  //public isLoggedIn:BehaviorSubject<boolean>= new BehaviorSubject(false)
  public isProfileSetPageCompleted: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public profilePic: BehaviorSubject<string> = new BehaviorSubject(
    "https://india-live-api.talentasker.com/user.png"
  );
  public isSocilaLogin: boolean = false;
  // public isManualLogin:boolean = true;
  public isProfieleUpdated: boolean = false;
  public categoriesList: any = {};

  private findurlSubject = new BehaviorSubject<boolean>(true);
  public findurl = this.findurlSubject.asObservable();


  constructor(private http: HttpClient) {}

  setFindurl(value: boolean) {
    this.findurlSubject.next(value);
  }

  getFindurl(): boolean {
    return this.Findurl;
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getLocation(position): Observable<any> {
    //AIzaSyDd016l4bj1QSG0Oj6LGgAsB6-CFXu77-E
    // return this.http.get(
    //   "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
    //   position.latitude +
    //   "," +
    //   position.longitude +
    //   "&key=AIzaSyC3WXQDiGGHZ3e836OvmlFhPf70DwBpKSE"
    // );
    return this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      position.latitude +
      "," +
      position.longitude +
      "&key=AIzaSyDd016l4bj1QSG0Oj6LGgAsB6-CFXu77-E"
    );
  }
  addCategories(data,token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/addcategory`, data,
    { headers: { talentasker: token } }
  );
  }
  getCompletedJobsCount(token): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/admin/` ,{ headers: { talentasker: token } }
    );
  }
  checkUserAvailability(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/userCheckID`, data);
  }
  customerSignup(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/register`,
      data
    );
  }
  getShortLink(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
     };
     // AIzaSyDd016l4bj1QSG0Oj6LGgAsB6-CFXu77-E
      // return this.http.post(
      //   "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC3WXQDiGGHZ3e836OvmlFhPf70DwBpKSE",
      //   data,httpOptions
      // ); 
      return this.http.post(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDd016l4bj1QSG0Oj6LGgAsB6-CFXu77-E",
        data,httpOptions
      ); 
         
    }
  getRefferalData(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/fetchReferralUsers_Earnings`,
      data,
      { headers: { talentasker: token } }
    );
  }
  login(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/login`, data);
  }
  logOut(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/signout`,
      data,
      { headers: { talentasker: token } }
    );
  }
  socialLogin(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/social_media`,
      data
    );
  }
  fetchUserDetails(id,token): Observable<any> {    
    return this.http.post(
      `${this.baseUrl}/api/customer/fetch`,
      id,{ headers: { talentasker: token } }
    );
  }
  updatePhoneVerificationStatus(data, token) {
    return this.http.post(
      `${this.baseUrl}/api/customer/updatemobileverify`,
      data,
      { headers: { talentasker: token } }
    );
  }
  fetchUserReviews(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/ratings/fetch_reviews`,
      data,
      { headers: { talentasker: token } }
    );
  }
  verify(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/verify`,
      data
    );
  }
  withdrawAmountApi(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/hire/withDrawReferralAmount`,
      data,
      { headers: { talentasker: token } }
    );
  }
  setProfile(data, token): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/customer/`, data, {
      headers: { talentasker: token },
    });
  }
  forgotPassword(data): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/forgot`,
      data
    );
  }
  isThisAccountElegibleToDelete(data, token): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/DeleteEligiable`, data, {
      headers: { talentasker: token },
    });
  }
  deleteMyAccount(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        talentasker: token,
      }),
      body: data,
    };
    return this.http.delete(`${this.baseUrl}/api/customer/account`, options);
  }
  resetPassword(data,token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/reset_password`,
      data     
    );
  }
  addSkills(data,token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/settings/skill_updatea`,    
      data,
      { headers: { talentasker: token } }
    );
  }
  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }
  eHiring(data: any, token: string): Observable<any> {    
    return this.http.post(`${this.baseUrl}/api/hiring/insert`, data, {
      headers: { talentasker: token },
    });
  }

  getDefaultCountry(): Observable<any> {
    return this.http.get("https://india-live-api.talentasker.com/api/admin/defaultcountryfetch");
  }

  getDefaultVerificationToken(data): Observable<any> {
    return this.http.post("https://india-live-api.talentasker.com/api/admin/defaultVerificationTokengenerate", data);
  }

}